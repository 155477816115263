ar<template>
  <div
    class="c-section-title"
    :class="{ 'c-section-title--deco': deco, 'c-section-title--mb': mb, 'c-section-title--mt': mt }"
  >
    <template v-if="typeof title === 'string'">
      <p class="title">
        <!--  props 或者 slot 擇一 -->
        <template v-if="title">
          {{ title }}
        </template>

        <template v-else>
          <slot></slot>
        </template>
      </p>
    </template>

    <template v-else>
      <p class="title" v-for="t of title" :key="t">
        {{ t }}
      </p>
    </template>
    <!-- <div v-show="deco" class="deco"></div> -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SectionTitle',
  props: {
    title: {
      type: [String, Array],
      default: '',
    },
    deco: {
      type: Boolean,
      default: true,
    },
    // margin-bottom
    mb: {
      type: Boolean,
      default: true,
    },
    mt: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/utils/_rwd.scss';
.c-section-title {
  position: relative;

  &--deco {
    &::after {
      content: '';
      @include position(bl, -20px, 0);
      display: inline-block;
      background-color: $c-main-yellow;
      width: 100px;
      height: 5px;
    }
    margin-bottom: 0px;
  }

  &--mt {
    margin-top: 100px;
  }

  &--mb {
    margin-bottom: 70px;
  }
}
.title {
  @include font-style($c-main, 24, 500, 2.6px, 32px);
}

@media screen and (min-width: $tablet) {
  // .title {
  //   @include font-style($c-main, 25, 500, 1.4px);
  // }
}

@media screen and (min-width: $pc) {
  .title {
    @include font-style($c-main, 28, 500, 1.4px);
  }
}
</style>
