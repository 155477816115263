import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const scrollEle = document.querySelector('#app');

  // mom, home-care 測驗頁面不要讓滾輪回到最上方

  const selfCheckingPage = ['mom', 'home-care'];

  // history, experience-share 點擊 tab 不要回到最上方
  // 表示是 query 在變動
  const tabPossibilityPage = ['history', 'experience-share'];
  if (tabPossibilityPage.includes(to.name) && to.name === from.name) {
    next();
    return;
  }

  const stopScrollPageCondition = selfCheckingPage.includes(to.name) && to.params.status !== 'result';

  if (!stopScrollPageCondition) {
    if (scrollEle) {
      scrollEle.scrollTo({
        top: 0,
        left: 0,
      });
    }
  } else if (scrollEle) {
    scrollEle.scrollTo({
      top: 500,
      left: 0,
    });
  }

  next();
});

export default router;
