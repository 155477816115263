import VueClickAway from 'vue3-click-away';
import { createApp } from 'vue';
// import './registerServiceWorker';

import Maska from 'maska';
import { createHead } from '@vueuse/head';
import Sheet from '@/components/Global/Sheet.vue';
import Paragraph from '@/components/Global/Paragraph.vue';
import Accordion from '@/components/Global/Accordion.vue';
import SectionTitle from '@/components/Global/SectionTitle.vue';
import Loading from '@/components/Global/Loading.vue';
import '@/styles/init/index.scss';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

const head = createHead();

const app = createApp(App);
// 常用
app.component('SectionTitle', SectionTitle);
app.component('Sheet', Sheet);
app.component('Paragraph', Paragraph);
app.component('Accordion', Accordion);
app.component('Loading', Loading);

app
  .use(store)
  .use(router)
  .use(head)
  .use(VueClickAway)
  .use(Maska)
  .mount('#app');
