export default [
  {
    path: '/society',
    name: 'society',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '社會責信',
      siteMap: {
        title: '社會責信',
        show: true,
        order: 5,
      },
    },
    children: [
      {
        path: 'donate-list',
        name: 'donate-list',
        component: () => import(/* webpackChunkName: "DonateList" */ '@/views/Society/DonateList.vue'),
        meta: {
          title: '捐款徵信錄',
          banner: {
            subTitle: '社會責信',
            bgName: 'banner-donate',
          },
        },
      },
      {
        path: 'assistance',
        name: 'assistance',
        component: () => import(/* webpackChunkName: "Assistance" */ '@/views/Society/Assistance.vue'),
        meta: {
          title: '受助清冊',
          banner: {
            subTitle: '社會責信',
            bgName: 'banner-donate',
          },
        },
      },
      {
        path: 'work-report',
        name: 'work-report',
        component: () => import(/* webpackChunkName: "WorkReport" */ '@/views/Society/WorkReport.vue'),
        meta: {
          title: '工作報告',
          banner: {
            subTitle: '社會責信',
            bgName: 'banner-report',
          },
        },
      },
      {
        path: 'financial-report',
        name: 'financial-report',
        component: () => import(/* webpackChunkName: "FinancialReport" */ '@/views/Society/FinancialReport.vue'),
        meta: {
          title: '財務報告',
          banner: {
            subTitle: '社會責信',
            bgName: 'banner-report',
          },
        },
      },

    ],
  },
];
