import { request, resetPasswordRequest } from '@/api';

export const resetPassword = async (payload) => {
  const res = await resetPasswordRequest({
    method: 'post',
    url: '/users/repw',
    data: payload,
  });

  return res.data;
};
export const loginAPI = async (payload) => {
  const res = await request({
    method: 'post',
    url: '/login',
    data: payload,
  });
  return res.data;
};

export const forgetPasswordAPI = async (payload) => {
  const res = await request({
    method: 'post',
    url: '/login/forget',
    data: payload,
  });
  return res.data;
};

export const registerAPI = async (payload) => {
  const res = await request({
    method: 'post',
    url: '/users/sign-up',
    data: payload,
  });
  return res.data;
};

export const getMemberInfoAPI = async (payload) => {
  const res = await request({
    method: 'get',
    url: '/users',
    data: payload,
  });
  return res.data;
};

export const updateMemberInfoAPI = async (payload) => {
  const res = await request({
    method: 'post',
    url: '/users',
    data: payload,
  });
  return res.data;
};

export const changePasswordAPI = async (payload) => {
  const res = await request({
    method: 'post',
    url: '/users/chpw',
    data: payload,
  });
  return res.data;
};

export const getEventInfoAPI = async (payload) => {
  const res = await request({
    method: 'get',
    url: '/users/events',
    data: payload,
  });
  return res.data;
};
