<template>
  <router-view />
</template>
<script>
import { defineComponent, onMounted } from 'vue';
import { debounce } from 'lodash-es';
import { useSiteStore } from '@/store/site';
import cusStorage from '@/utils/cusStorage';
import { getMemberInfoAPI } from '@/api/members';

export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const siteStore = useSiteStore();
    const content = 'veu meta test content...';
    const siteTitle = '台灣早產兒基金會 | Premature Baby Foundation of Taiwan.';

    const reCalcWindowWidthDebounce = debounce(() => {
      siteStore.setDeviceType();
    }, 100);

    const handleAuth = async () => {
      if (cusStorage.getItem('token')) {
        siteStore.setIsLogin(true);
        const { status: getMemberInfoStatus, result: memberInfo } = await getMemberInfoAPI();

        if (getMemberInfoStatus) {
          siteStore.setMemberInfo(memberInfo);
        }
      }
    };

    // http://localhost:8080/member/member-info

    onMounted(() => {
      handleAuth();
      const appRoot = document.querySelector('#app');
      siteStore.setAppRoot(appRoot);
      siteStore.setDeviceType();
      window.addEventListener('resize', reCalcWindowWidthDebounce);
    });

    return {
      content,
      siteTitle,
    };
  },
});
</script>
<style lang="scss">
@import '~@/styles/utils/_rwd.scss';

.g-content {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: $mobile) {
  .g-content {
    // padding: 0px;
  }
}
</style>
