export default [
  {
    path: '/donate',
    name: 'donate',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '捐款贊助',
      siteMap: {
        title: '捐款贊助',
        show: true,
        order: 6,
      },
    },
    children: [
      {
        path: 'donate-channel',
        name: 'donate-channel',
        component: () => import(/* webpackChunkName: "DonateChannel" */ '@/views/Donate/DonateChannel.vue'),
        meta: {
          title: '捐款方式',
          banner: {
            subTitle: '捐款贊助',
            bgName: 'banner-donate',
          },
        },
      },
      {
        path: 'donate-question',
        name: 'donate-question',
        component: () => import(/* webpackChunkName: "DonateQuestion" */ '@/views/Donate/DonateQuestion.vue'),
        meta: {
          title: '捐款常見問題',
          banner: {
            subTitle: '捐款贊助',
            bgName: 'banner-qa',
          },
        },
      },
    ],
  },
];
