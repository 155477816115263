import { defineStore } from 'pinia';
import cusStorage from '@/utils/cusStorage';

export const useSiteStore = defineStore({
  id: 'site',
  state: () => ({
    isLogin: false,
    token: '',
    memberInfo: {

    },
    appRoot: null,
    deviceType: 'init',
    seo: {},

  }),

  actions: {
    setIsLogin(status) {
      this.isLogin = status;
    },
    setToken(token) {
      this.token = token;
      this.isLogin = true;
      cusStorage.setItem('token', token);
    },
    setMemberInfo(memberInfo) {
      this.memberInfo = memberInfo;
    },
    handleLogout() {
      this.isLogin = false;
      this.token = '';
      cusStorage.removeItem('token');
    },
    setAppRoot(ele) {
      this.appRoot = ele;
    },
    setDeviceType() {
      const width = document.body.clientWidth;

      const typeCollect = [];

      const rwdTyepMatching = {
        320: 'init',
        576: 'mobile',
        768: 'portrait',
        1024: 'tablet',
        1440: 'sm-pc',
        1600: 'pc',
        2560: 'lg-pc',
      };

      const widthList = [
        320,
        576,
        768,
        1024,
        1440,
        1600,
        2560,
      ];

      widthList.forEach((w) => {
        if (width >= w) {
          typeCollect.push(rwdTyepMatching[String(w)]);
        }
      });

      this.deviceType = typeCollect;
    },
    saveSeo(seoData) {
      const seoPageMapping = seoData.reduce((obj, data) => {
        const extraPath = data.url.replace('{{FRONT_URL}}', '');
        const pathName = extraPath === '' ? 'root' : extraPath;

        obj[pathName] = data;

        return obj;
      }, {});

      this.seo = seoPageMapping;
    },
  },

});

export const a = '';
