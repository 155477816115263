export default [
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '最新消息',
      siteMap: {
        title: '最新消息',
        show: true,
        order: 3,
      },
    },
    children: [
      {
        path: 'announcements',
        name: 'announcements',
        component: () => import(/* webpackChunkName: "Announcements" */ '@/views/News/Announcements.vue'),
        meta: {
          title: '一般公告',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
        },
      },
      {
        path: 'announcement',
        name: 'announcement',
        component: () => import(/* webpackChunkName: "Announcement" */ '@/views/News/Announcement.vue'),
        meta: {
          title: '一般公告',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
          hideInMenu: true,
        },
      },

      {
        path: 'activity-lessons',
        name: 'activity-lessons',
        component: () => import(/* webpackChunkName: "ActivityLessons" */ '@/views/News/ActivityLessons.vue'),
        meta: {
          title: '活動/課程',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
        },
      },
      {
        path: 'activity-lesson',
        name: 'activity-lesson',
        component: () => import(/* webpackChunkName: "ActivityLesson" */ '@/views/News/ActivityLesson.vue'),
        meta: {
          title: '活動/課程',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
          hideInMenu: true,
        },
      },
      {
        path: 'activity-lesson/parent-apply',
        name: 'activity-lesson-parent-apply',
        component: () => import(/* webpackChunkName: "ParentApply" */ '@/views/News/ActivityLesson/ParentApply.vue'),
        meta: {
          title: '活動報名',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
          hideInMenu: true,
        },
      },
      {
        path: 'activity-lesson/team-apply',
        name: 'activity-lesson-team-apply',
        component: () => import(/* webpackChunkName: "TeamApply" */ '@/views/News/ActivityLesson/TeamApply.vue'),
        meta: {
          title: '活動報名',
          banner: {
            subTitle: '最新消息',
            bgName: 'banner-news',
          },
          hideInMenu: true,
        },
      },

    ],
  },
];
