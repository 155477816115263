export default [
  {
    path: '/member',
    name: 'member',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '會員專區',
      siteMap: {
        title: '會員專區',
        show: false,
        order: 3,
      },
    },
    children: [

      {
        path: 'member-info',
        name: 'member-info',
        component: () => import(/* webpackChunkName: "MemberInfo" */ '@/views/Member/MemberInfo.vue'),
        meta: {
          title: '會員資料變更',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
          hideBreadcrumb: true,
        },
      },
      {
        path: 'add-baby-info',
        name: 'add-baby-info',
        component: () => import(/* webpackChunkName: "AddEditBabyInfo" */ '@/views/Member/AddEditBabyInfo.vue'),
        meta: {
          title: '會員專區',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
        },
      },
      {
        path: 'edit-baby-info',
        name: 'edit-baby-info',
        component: () => import(/* webpackChunkName: "AddEditBabyInfo" */ '@/views/Member/AddEditBabyInfo.vue'),
        meta: {
          title: '會員專區',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
        },
      },
      {
        path: 'baby-info',
        name: 'baby-info',
        component: () => import(/* webpackChunkName: "BabyInfo" */ '@/views/Member/BabyInfo.vue'),
        meta: {
          title: '寶寶資訊',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
          hideBreadcrumb: true,

        },
      },
      {
        path: 'baby-info/edit/:id',
        name: 'baby-info-edit',
        component: () => import(/* webpackChunkName: "BabyInfoEdit" */ '@/views/Member/BabyInfoEdit.vue'),
        meta: {
          title: '編輯寶寶資訊',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
          hideBreadcrumb: true,

        },
      },

      {
        path: 'change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/Member/ChangePassword.vue'),
        meta: {
          title: '變更密碼',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
          hideBreadcrumb: true,
        },

      },

      {
        path: 'apply',
        name: 'apply',
        component: () => import(/* webpackChunkName: "Apply" */ '@/views/Member/Apply.vue'),
        meta: {
          title: '我的報名',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
          auth: true,
          hideBreadcrumb: true,
        },
      },

    ],
  },
];
