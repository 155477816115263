<template>
  <div class="c-loading">
    <div class="loader loader--style2" title="1">
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <path
          fill="#000"
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loading',
});
</script>
<style lang="scss" scoped>

.c-loading {
  @include flex(center);
}
.loader {
  // margin: 0 0 2em;
  // width: 10px;
  // height: 10px;
  // width: 20%;
  text-align: center;
  // padding: 1em;
  // margin: 0 auto 1em;
  display: inline-block;
  // vertical-align: top;
}

svg path,
svg rect {
  fill: $c-table-row-stripe;
}
</style>
