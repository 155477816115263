export default [
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '小腳丫資源',
      siteMap: {
        title: '小腳丫資源',
        show: true,
        order: 4,
      },
    },
    children: [
      {
        path: 'brochure',
        name: 'brochure',
        component: () => import(/* webpackChunkName: "Brochure" */ '@/views/Resources/Brochure.vue'),
        meta: {
          title: '文宣宣傳',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-brochure',
          },
        },
      },
      {
        path: 'medias',
        name: 'medias',
        component: () => import(/* webpackChunkName: "Medias" */ '@/views/Resources/Medias.vue'),
        meta: {
          title: '影音專區',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-film',
          },
        },
      },
      {
        path: 'media',
        name: 'media',
        component: () => import(/* webpackChunkName: "Media" */ '@/views/Resources/Media.vue'),
        meta: {
          title: '影音專區',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-film',
          },
          hideInMenu: true,
        },
      },
      {
        path: 'health-education',
        name: 'health-education',
        // redirect: '/resources/health-education/pregnant-risk-and-safety',
        component: () => import(/* webpackChunkName: "HealthEducation" */ '@/views/Resources/HealthEducation.vue'),
        meta: {
          title: '衛教百寶箱',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-turn-page',
          },
        },
        children: [
          {
            path: 'pregnant-risk-and-safety',
            name: 'pregnant-risk-and-safety',
            component: () => import(/* webpackChunkName: "PregnantRiskAndSafety" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety.vue'),
            meta: {
              title: '高危險妊娠與安胎',
              banner: {
                subTitle: '衛教百寶箱',
                bgName: 'banner-turn-page',
              },
              showInMenuChild: true,
            },
          },

          // for testing---start
          {
            path: 'pregnant-risk-and-safety/recognize-high-risk-pregnancy',
            name: 'recognize-high-risk-pregnancy',
            component: () => import(/* webpackChunkName: "RecognizeHighRiskPregnancy" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/RecognizeHighRiskPregnancy.vue'),
            meta: {
              title: '認識高危險妊娠',
              banner: {
                subTitle: '高危險妊娠',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/risk-pregnancy-managment-and-treatment',
            name: 'risk-pregnancy-managment-and-treatment',
            component: () => import(/* webpackChunkName: "RiskPregnancyManagementAndTreatment" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/RiskPregnancyManagementAndTreatment.vue'),
            meta: {
              title: '常見高危險妊娠處置及治療',
              banner: {
                subTitle: '高危險妊娠',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/hospital-miscarriage-prevent',
            name: 'hospital-miscarriage-prevent',
            component: () => import(/* webpackChunkName: "HospitalMiscarriagePrevent" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/HospitalMiscarriagePrevent.vue'),
            meta: {
              title: '住院安胎大小事',
              banner: {
                subTitle: '認識安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/home-miscarriage-prevent',
            name: 'home-miscarriage-prevent',
            component: () => import(/* webpackChunkName: "HomeMiscarriagePrevent" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/HomeMiscarriagePrevent.vue'),
            meta: {
              title: '居家安胎大小事',
              banner: {
                subTitle: '認識安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/miscarriage-prevent-diet',
            name: 'miscarriage-prevent-diet',
            component: () => import(/* webpackChunkName: "MiscarriagePreventDiet" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/MiscarriagePreventDiet.vue'),
            meta: {
              title: '安胎飲食原則',
              banner: {
                subTitle: '認識安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/highrisk-pregnant-emergency-hospital',
            name: 'highrisk-pregnant-emergency-hospital',
            component: () => import(/* webpackChunkName: "MiscarriagePreventDiet" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/HighriskPregnantEmergencyHospital.vue'),
            meta: {
              title: '高危險妊娠急救責任醫院',
              banner: {
                subTitle: '認識安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          // for testing---end

          // pregnant-risk-and-safety start
          {
            path: 'pregnant-risk-and-safety/premature-delivery-risk-group',
            name: 'premature-delivery-risk-group',
            component: () => import(/* webpackChunkName: "PrematureDeliveryRiskGroup" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/PrematureDeliveryRiskGroup.vue'),
            meta: {
              title: '高危險妊娠',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/premature-delivery',
            name: 'premature-delivery',
            component: () => import(/* webpackChunkName: "PrematureDelivery" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/PrematureDelivery.vue'),
            meta: {
              title: '認識安胎',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-turn-page',
              },
            },
          },

          {
            path: 'pregnant-risk-and-safety/premature-delivery-sign',
            name: 'premature-delivery-sign',
            component: () => import(/* webpackChunkName: "PrematureDeliverySign" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/PrematureDeliverySign.vue'),
            meta: {
              title: '早產的象徵',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-turn-page',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/notice-for-mom',
            name: 'notice-for-mom',
            component: () => import(/* webpackChunkName: "NoticeForMom" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/NoticeForMom.vue'),
            meta: {
              title: '給媽媽的叮嚀',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-health-education',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/miscarriage-prevent-warning',
            name: 'miscarriage-prevent-warning',
            component: () => import(/* webpackChunkName: "MiscarriagePreventWarning" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/MiscarriagePreventWarning.vue'),
            meta: {
              title: '安胎注意事項',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-health-education',
              },
            },
          },
          {
            path: 'pregnant-risk-and-safety/miscarriage-prevent-qa',
            name: 'miscarriage-prevent-qa',
            component: () => import(/* webpackChunkName: "MiscarriagePreventQA" */ '@/views/Resources/HealthEducation/PregnantRiskAndSafety/MiscarriagePreventQA.vue'),
            meta: {
              title: '安胎Q&A',
              banner: {
                subTitle: '高危險妊娠與安胎',
                bgName: 'banner-health-education',
              },
            },
          },
          // pregnant-risk-and-safety end

          {
            path: 'diseases',
            name: 'diseases',
            component: () => import(/* webpackChunkName: "Diseases" */ '@/views/Resources/HealthEducation/Diseases.vue'),
            meta: {
              title: '早產相關疾病與衛教',
              banner: {
                subTitle: '衛教百寶箱',
                bgName: 'banner-resources',
              },
              showInMenuChild: true,
            },

          },
          // disease inner - start
          {
            path: 'diseases/what-is-preemie',
            name: 'what-is-preemie',
            component: () => import(/* webpackChunkName: "WhatIsPreemie" */ '@/views/Resources/HealthEducation/Diseases/WhatIsPreemie.vue'),
            meta: {
              title: '認識早產與矯正年齡',
              banner: {
                subTitle: '早產相關疾病與衛教',
                bgName: 'banner-resources',
              },
            },
          },
          {
            path: 'diseases/preemie-in-hospital',
            name: 'preemie-in-hospital',
            component: () => import(/* webpackChunkName: "PreemieInHospital" */ '@/views/Resources/HealthEducation/Diseases/PreemieInHospital.vue'),
            meta: {
              title: '早產兒住院期間面臨的問題',
              banner: {
                subTitle: '早產相關疾病與衛教',
                bgName: 'banner-resources',
              },
            },
          },
          {
            path: 'diseases/prepare-to-discharged-from-hospital',
            name: 'prepare-to-discharged-from-hospital',
            component: () => import(/* webpackChunkName: "PrepareToDischargedFromHospital" */ '@/views/Resources/HealthEducation/Diseases/PrepareToDischargeFromHospital.vue'),
            meta: {
              title: '早產兒出院準備',
              banner: {
                subTitle: '早產相關疾病與衛教',
                bgName: 'banner-resources',
              },
            },
          },
          {
            path: 'diseases/home-take-care',
            name: 'home-take-care',
            component: () => import(/* webpackChunkName: "HomeTakeCare" */ '@/views/Resources/HealthEducation/Diseases/HomeTakeCare.vue'),
            meta: {
              title: '一般居家照護',
              banner: {
                subTitle: '早產相關疾病與衛教',
                bgName: 'banner-resources',
              },
            },
          },
          // disease inner - end

          {
            path: 'low-weight-checking',
            name: 'low-weight-checking',
            component: () => import(/* webpackChunkName: "LowWeightChecking" */ '@/views/Resources/HealthEducation/LowWeightChecking.vue'),
            meta: {
              title: '極低出生體重追蹤檢查',
              banner: {
                subTitle: '早產相關疾病與衛教',
                bgName: 'banner-resources',
              },
              showInMenuChild: true,
            },
          },
          {
            path: 'social-welfare',
            name: 'social-welfare',
            component: () => import(/* webpackChunkName: "SocialWelfare" */ '@/views/Resources/HealthEducation/SocialWelfare.vue'),
            meta: {
              title: '早產兒社會福利資源',
              banner: {
                subTitle: '衛教百寶箱',
                bgName: 'banner-resources',
              },
              showInMenuChild: true,
            },
          },
        ],
      },
      {
        path: 'self-checking',
        name: 'self-checking',
        component: () => import(/* webpackChunkName: "SelfChecking" */ '@/views/Resources/SelfChecking.vue'),
        meta: {
          title: '自我檢測',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-self-checking',
          },
        },
      },
      // self-checking start
      {
        path: 'self-checking/mom/:status', // 可能是題數 1,2,3 ... 也可能是結果 result
        name: 'mom',
        component: () => import(/* webpackChunkName: "Mom" */ '@/views/Resources/SelfChecking/Mom.vue'),
        meta: {
          title: '準媽媽區',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-self-checking',
          },
          hideInMenu: true,
        },
      },
      {
        path: 'self-checking/home-care/:status',
        name: 'home-care',
        component: () => import(/* webpackChunkName: "HomeCare" */ '@/views/Resources/SelfChecking/HomeCare.vue'),
        meta: {
          title: '居家照顧',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-self-checking',
          },
          hideInMenu: true,
        },
      },

      // self-checking end
      {
        path: 'related-sites',
        name: 'related-sites',
        component: () => import(/* webpackChunkName: "RelatedSites" */ '@/views/Resources/RelatedSites.vue'),
        meta: {
          title: '相關網站',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-related-sites',
          },

        },
      },
      {
        path: 'qa',
        name: 'qa',
        component: () => import(/* webpackChunkName: "QA" */ '@/views/Resources/QA.vue'),
        meta: {
          title: '常見問題',
          banner: {
            subTitle: '小腳丫資源',
            bgName: 'banner-qa',
          },
        },
      },
    ],
  },
];
