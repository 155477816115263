<template>
  <div class="c-sheet" :style="style">

    <slot />
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Sheet',
  props: {
    maxWidth: {
      type: String,
      default: '',
    },

  },
  setup(props) {
    const style = computed(() => {
      let cusStyle = {};
      if (props.maxWidth) {
        cusStyle = {
          maxWidth: props.maxWidth > 1100 ? '1100px' : `${props.maxWidth}px`,
        };
      }

      return cusStyle;
    });

    return {
      style,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/utils/_rwd.scss';
.c-sheet {
  @include max-width(1100);
  width: 100%;
  margin: 0 auto;

}

</style>
