<template>
  <div class="c-paragraph">
    <template v-for="(t, idx) of wording" :key="`${t.text}${idx}`">
    <div v-if="t.render" :style="t.style">
      <component  :is="t.render" />
      </div>
      <p
        v-if="typeof t.text === 'string'"
        class="text"
        :class="['text', `text--${t.type}`]"
        :style="t.style ? t.style : null"
      >
        {{ t.text }}
      </p>
      <template v-else>
        <p class="text-loop" :style="t.loopWrapStyle ? t.loopWrapStyle : null">
          <span
            v-for="(w, i) of t.text"
            :key="w"
            :class="['text-loop__word', `text-loop__word--${t.type}`]"
            :style="i === 0 && t.style ? t.style : null"
          >
            {{ w }}
          </span>
        </p>
      </template>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Paragraph',
  props: {
    wording: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-paragraph {
  width: 100%;
  .text {
    &:first-child {
      margin-top: 0;
    }
  }
}

.text {
  text-align: justify;
  text-align-last: left;
  word-break: break-all;

  &--large {
    @include font-style($c-main, 35, 700, 3px, 51px);
    margin-top: 20px;
  }

  &--normal {
    @include font-normal();
    margin-top: 10px;
  }

  &--normal-indent {
    @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
    padding-left: 16px;
  }

  &--normal-bold {
    @include font-style($c-main, $f-normal-text, 700, 1.6px, 28px);
    margin-top: 20px;
  }

  &--normal-underline {
    @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
    text-decoration: underline;
    margin-top: 20px;
  }

  &--important {
    @include font-important();
    margin-top: 40px;
  }

  &--important-bold {
    @include font-important();
    font-weight: 700;
    margin-top: 40px;
  }

  &--important-center {
    @include font-important();
    text-align: center;
    margin-top: 20px;
  }
}

.text-loop {
  text-align: justify;
  text-align-last: left;
  word-break: break-all;

  &__word {
    display: block;
    text-align: justify;
    text-align-last: left;

    &:first-child {
      margin-top: 20px;
    }

    & + & {
      margin-top: 0;
    }

    &--large {
      @include font-style($c-main, 35, 700, 3px, 51px);
    }

    &--normal {
      @include font-normal();
      margin-top: 10px;

      &:first-child {
        margin-top: 10px;
      }
    }

    &--normal-indent {
      @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
      padding-left: 16px;
    }

    &--normal-bold {
      @include font-style($c-main, $f-normal-text, 700, 1.6px, 28px);
      margin-top: 20px;
    }

    &--normal-underline {
      @include font-style($c-main, $f-normal-text, 400, 1.6px, 28px);
      text-decoration: underline;
    }

    &--important {
      @include font-important();
      margin-top: 40px;
    }

    &--important-bold {
    @include font-important();
    font-weight: 700;
    margin-top: 40px;
  }

    &--important-center {
      @include font-important();
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
