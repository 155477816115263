export default [
  {
    path: '/foundation',
    name: 'foundation',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '腳丫的基金會',
      siteMap: {
        title: '腳丫的基金會',
        show: true,
        order: 1,
      },
    },
    children: [
      {
        path: 'about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/Foundation/AboutUs.vue'),
        meta: {
          title: '認識我們',
          banner: {
            subTitle: '腳丫的基金會',
            bgName: 'banner-about',
          },
        },
      },
      {
        path: 'history',
        name: 'history',
        component: () => import(/* webpackChunkName: "History" */ '@/views/Foundation/History.vue'),
        meta: {
          title: '發展與沿革',
          banner: {
            subTitle: '腳丫的基金會',
            bgName: 'banner-about',
          },
        },
      },
      {
        path: 'stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "Stories" */ '@/views/Foundation/Stories.vue'),
        meta: {
          title: '珍愛故事',
          banner: {
            subTitle: '腳丫的基金會',
            bgName: 'banner-cherish-story',
          },
        },
      },
      {
        path: 'story',
        name: 'story',
        component: () => import(/* webpackChunkName: "Story" */ '@/views/Foundation/Story.vue'),
        meta: {
          title: '珍愛故事',
          banner: {
            subTitle: '腳丫的基金會',
            bgName: 'banner-cherish-story',
          },
          hideInMenu: true,
        },
      },

    ],
  },
];
