import { getResetPwToken } from '@/api/libs/auth';
import restful from '@/api/libs/restful';

const config = {
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  timeout: 100000,
};

export const request = restful(config);

export const resetPasswordRequest = restful({
  ...config,
  authFunc: getResetPwToken,
});
