// 根路徑下，不需要預設 layout 的頁面
export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Base/Home/index.vue'),
    meta: {
      title: '首頁',
      siteMap: {
        show: false,
      },
    },
  },

  {
    path: '/landing',
    exact: true,
    name: 'landing',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Landing.vue'),
    meta: {
      title: '落地頁',
      siteMap: {
        show: false,
      },

    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "Test" */ '@/views/Base/Test2.vue'),
    meta: {
      title: '測試',
      siteMap: {
        show: false,

      },

    },
  },
  {
    path: '/:pathMatch(.*)*',
    exact: true,
    name: 'error-404',
    component: () => import(/* webpackChunkName: "Error404" */ '@/views/Error404.vue'),
    meta: {
      title: 'error-404',
      siteMap: {
        show: false,
      },
    },
  },

  {
    path: '/testing',
    exact: true,
    name: 'testing',
    component: () => import(/* webpackChunkName: "Testing" */ '@/views/Testing.vue'),
    meta: {
      title: 'testing',
      siteMap: {
        show: false,
      },
    },
  },

];
