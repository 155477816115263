// 根路徑下，需要預設 layout 的頁面

export default [
  {
    path: '/',
    name: '',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '空',
      siteMap: {
        title: '空',
        show: false,
      },
    },
    children: [
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "Register" */ '@/views/Member/Register.vue'),
        meta: {
          title: '註冊',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
        },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Member/Login.vue'),
        meta: {
          title: '登入',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
        },
      },
      {
        path: 'forget-password',
        name: 'forget-password',
        component: () => import(/* webpackChunkName: "ForgetPassword" */ '@/views/Member/ForgetPassword.vue'),
        meta: {
          title: '忘記密碼',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
        },
      },
      {
        path: 'help',
        name: 'help',
        component: () => import(/* webpackChunkName: "Help" */ '@/views/Base/Help.vue'),
        meta: {
          title: '我要提問',
          banner: {
            subTitle: '',
            bgName: 'banner-question',
          },
        },
      },
      {
        path: 'subscribe',
        name: 'subscribe',
        component: () => import(/* webpackChunkName: "Subccribe" */ '@/views/Base/Subscribe.vue'),
        meta: {
          title: '訂閱電子報',
          banner: {
            subTitle: '',
            bgName: 'banner-newsletter',
          },
        },
      },
      {
        path: 'unsubscribe',
        name: 'unsubscribe',
        component: () => import(/* webpackChunkName: "Unsubscribe" */ '@/views/Base/Unsubscribe.vue'),
        meta: {
          title: '取消訂閱電子報',
          banner: {
            subTitle: '',
            bgName: 'banner-newsletter',
          },
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Base/Privacy.vue'),
        meta: {
          title: '隱私權政策',
          siteMap: {
            subTitle: '隱私權政策',
            show: false,
          },
          banner: {
            bgName: 'banner-about',
          },
        },
      },
      {
        path: 'term-of-service',
        name: 'term-of-service',
        component: () => import(/* webpackChunkName: "TermOfService" */ '@/views/Base/TermOfService.vue'),
        meta: {
          title: '服務條款',
          siteMap: {
            show: false,
          },
          banner: {
            subTitle: '服務條款',
            bgName: 'banner-about',
          },
        },
      },
      {
        path: 'resetpassword',
        name: 'resetpassword',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Member/ResetPassword.vue'),
        meta: {
          title: '重設密碼',
          banner: {
            subTitle: '會員專區',
            bgName: 'banner-member',
          },
        },
      },
    ],
  },
];
